import { ElementRef,AfterViewInit,Component, OnInit,ViewChild,NgModule ,Inject ,Input,Output,EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { FotoDialogComponent } from 'app/shared/foto-dialog/foto-dialog.component';
import { Paciente } from 'app/models/paciente.interface';
import { PacienteService } from 'app/services/paciente.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ObraSocialService } from 'app/services/obra-social.service';
import { TipoDocumentoService } from 'app/services/tipo-documento.service';
import { ObraSocial } from 'app/models/obraSocial.interface';
import { TipoDocumento } from 'app/models/tipoDocumento.interface';
import { TelefonoPais } from 'app/models/telefonoPais.interface';
import { TelefonoPaisService } from 'app/services/telefono-pais.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { AbstractControl } from '@angular/forms';
import { GlobalVariable } from 'app/global/global';
import { ThemePalette } from '@angular/material/core';
import { SpinnerService } from 'app/services/spinner.service';


@Component({
  selector: 'app-crear-form-paciente',
  templateUrl: './crear-form-paciente.component.html',
  styleUrls: ['./crear-form-paciente.component.scss']
})
export class CrearFormPacienteComponent implements OnInit {


  //
  funcionCorrecta:any;
  editarValor:boolean;
  url:string = GlobalVariable.BASE_API_URL+'storage/images/paciente/';
  @Output() listar:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() set editar(value:boolean) {
    console.log('value',value);
    this.editarValor = value;
    this.panelOpenState = (value) ? true : false;
  }
  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  @ViewChild('divImagen') divImagen: ElementRef;
  public telPais: FormControl = new FormControl();
  public celPais: FormControl = new FormControl();
  panelOpenState = false;
  file:File = null;
  nameImage:string = '';
  imagen = "";
  myfilename = 'Seleccionar archivo';
  button:string;
  paciente:Paciente;
  telefonoPais:TelefonoPais[];
  filtroTelefonoPais:TelefonoPais[] = [];
  filtroCelularPais:TelefonoPais[] = [];
  obrasSociales:ObraSocial[];
  tiposDocumentos:TipoDocumento[];
  color:ThemePalette = 'accent';
  checked = false;
  disabled = false;
  responseCreate:any;
  errorDocumento:any;
  errorNumeroDocumento:any;
  errorTipoDocumento:any;
  errorNumeroAfiliado:any;
  errorEmail:any;
  public telPaisBuscador:FormControl = new FormControl();
  public celPaisBuscador:FormControl = new FormControl();
  pacienteForm = new FormGroup({
    id: new FormControl(''),
    nombreApellido: new FormControl('',Validators.required),
    tipoDocumento: new FormControl('',Validators.required),
    numeroDocumento: new FormControl('',Validators.required),
    obraSocial: new FormControl(''),
    numeroAfiliado: new FormControl(''),
    direccion: new FormControl(''),
    fechaNacimiento: new FormControl(''),
    edad: new  FormControl(''),
    email: new FormControl('',[Validators.email]),
    telPaisBuscador: new FormControl(''),
    telPais: new FormControl(''),
    telCaract: new FormControl(''),
    telNumero: new FormControl(''),
    celPaisBuscador: new FormControl(''),
    celPais: new FormControl(''),
    celCaract: new FormControl(''),
    celNumero: new FormControl(''),
    codigoInterno: new FormControl(''),
    memberContactNo: new FormControl(''),
    modificar: new FormControl(true), 
    password: new FormControl('',Validators.required),
    repeatPassword: new FormControl('',[Validators.required]),
  },this.passwordMatchValidator);
  @Input() set response(value: any) {
    if(value){
      this.formMostrarValores(value);
      if(value.paciente){
        this.iniciarFormularioEditado(value.paciente);
      }
    }
  }


  //
  constructor(
    public fotoDialog: MatDialog,
    private pacienteService:PacienteService,
    private actRoute:ActivatedRoute,
    private router:Router,
    private apiObraSocial:ObraSocialService,
    private apiTipoDocumento:TipoDocumentoService,
    private apiTelefono:TelefonoPaisService,
    private snackBar: MatSnackBar,
    private spinnerService:SpinnerService,
  ) { }


  //
  ngOnInit(): void {}


  //
  ocultarMostrar(){
    this.panelOpenState = (this.panelOpenState == true) ? false : true;
  }


  //
  passwordMatchValidator(g: FormGroup) {
    return (g.get('password').value === g.get('repeatPassword').value) ? null : {'mismatch': true};
  }


  //
  clearNumeroDocumento(){
    this.errorNumeroDocumento = false;
    this.errorDocumento = false;
  }


  //
  clearTipoDocumento(){
    this.errorTipoDocumento = false;
    this.errorDocumento = false;
  }


  //
  clearNumeroAfiliado(){
    this.errorNumeroAfiliado = false;
  }


  //
  clearEmail(){
    this.errorEmail = false;
  }


  //
  formMostrarValores(value){
    this.telefonoPais = value.listaTelefonoPais;
    this.filtroTelefonoPais = value.listaTelefonoPais;
    this.filtroCelularPais = value.listaTelefonoPais;
    this.tiposDocumentos = value.listatipoDocumento;
    this.obrasSociales = value.obraSocial;
  }


  //
  iniciarFormularioEditado(data:any){
    this.paciente = data;
    if(!this.imagen || !this.imagen){
      this.imagen = `${this.url}${this.paciente.imagen}`;
      this.divImagen.nativeElement.style.display = 'block';
      this.nameImage = this.paciente.imagen;
    }    

    this.pacienteForm.patchValue({
      id: this.paciente.id,
      nombreApellido: (this.paciente.nombreApellido) ? this.paciente.nombreApellido : '',
      tipoDocumento: (this.paciente.tipoDocumento) ? this.paciente.tipoDocumento : '',
      numeroDocumento: (this.paciente.numeroDocumento) ? this.paciente.numeroDocumento : '',
      obraSocial: (this.paciente.obraSocial) ? this.paciente.obraSocial : '',
      numeroAfiliado: (!this.paciente.numeroAfiliado || this.paciente.numeroAfiliado == 'null') ? '' : this.paciente.numeroAfiliado,
      direccion: (this.paciente.direccion) ? this.paciente.direccion : '',
      fechaNacimiento: (this.paciente.fechaNacimiento) ? new Date(this.paciente.fechaNacimiento) : new Date(),
      edad: (this.paciente.edad) ? this.paciente.edad : '',
      email: (!this.paciente.email || this.paciente.email == 'null') ? '' : this.paciente.email,
      telPaisBuscador: '',
      telPais: (!this.paciente.telPais || this.paciente.telPais == 'null') ? '' : this.paciente.telPais,
      telCaract: (!this.paciente.telCaract || this.paciente.telCaract == 'null') ? '' : this.paciente.telCaract,
      telNumero: (!this.paciente.telNumero || this.paciente.telNumero == 'null') ? '' : this.paciente.telNumero,
      celPaisBuscador: '',
      celPais: (!this.paciente.celPais || this.paciente.celPais == 'null') ? '' : this.paciente.celPais,
      celCaract: (!this.paciente.celCaract || this.paciente.celCaract == 'null') ? '' : this.paciente.celCaract,
      celNumero: (!this.paciente.celNumero || this.paciente.celNumero == 'null') ? '' : this.paciente.celNumero,
      codigoInterno: (this.paciente.codigoInterno)  ? this.paciente.codigoInterno : '',
      memberContactNo: (this.paciente.imagen) ? this.paciente.imagen : 'Seleccionar archivo',
      modificar: ((this.paciente.modificar.toString() == '0') ? false : true), 
      password: this.paciente.password,
      repeatPassword: this.paciente.password,
    }); 
  }


  //
  filtrarTelPais(event:any){
    let textBuscador:string = this.telPaisBuscador.value;
    this.filtroTelefonoPais = this.telefonoPais.filter(function(element){
      if(element.nombre.toUpperCase().includes(textBuscador.toUpperCase())){
        return element;
      }
    });
  }


  //
  filtrarCelPais(event:any){
    let textBuscador:string = this.celPaisBuscador.value;
    this.filtroCelularPais = this.telefonoPais.filter(function(element){
      if(element.nombre.toUpperCase().includes(textBuscador.toUpperCase())){
        return element;
      }
    });
  }


  //
  cambiarModificar(event:any){
    this.pacienteForm.patchValue({
      modificar: event.checked,
    });
  }


  //
  guardarPaciente(form:any){
    this.funcionCorrecta = (this.editarValor) ? this.editarPaciente(form) : this.crearPaciente(form); 
  }


  //
  crearPaciente(paciente:Paciente){
    if(this.pacienteForm.valid){
      this.spinnerService.mostrarSpinner();
      this.pacienteService.crear(this.nameImage,paciente,this.file).subscribe(
        data => {
          let response:any = data;
          if(response.statusError){
            this.errorNumeroDocumento = response.errorNumeroDocumento;
            this.errorTipoDocumento = response.errorTipoDocumento;
            this.errorDocumento = response.errorDocumento;
            this.errorNumeroAfiliado = response.errorNumeroAfiliado;
            this.errorEmail = response.errorEmail;
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Guardado fallido","error");
          }
          else{
            this.listar.emit();
            this.resetearFormulario();
            this.openSnackBar("Guardado exitoso","exito");
          }
        },
        error => {
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Guardado fallido","error");
        }
      );
    }
    else{
      this.openSnackBar("Guardado fallido","error");
    }
  }


  //
  editarPaciente(paciente:any){
    if(this.pacienteForm.valid){
      this.spinnerService.mostrarSpinner();
      this.pacienteService.editar(this.nameImage,paciente,this.file).subscribe(
        data => {
          let response:any = data;
          if(response.statusError){
            this.errorNumeroDocumento = response.errorNumeroDocumento;
            this.errorTipoDocumento = response.errorTipoDocumento;
            this.errorDocumento = response.errorDocumento;
            this.errorNumeroAfiliado = response.errorNumeroAfiliado;
            this.errorEmail = response.errorEmail;
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Editado fallido","error");
          }
          else{
            this.openSnackBar("Editado exitoso","exito");
            this.router.navigate(['/seccion-panel/paciente/']);
          }
        },
        error => {   
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Editado fallido","error");
        }
      );
    }
    else{
      this.openSnackBar("Editado fallido","error");
    }
  }


  //
  resetearFormulario(){
    this.file = null;
    this.pacienteForm.reset();
    this.imagen = "";
    this.pacienteForm.patchValue({
      id: '',
      nombreApellido: '',
      tipoDocumento: '',
      numeroDocumento: '',
      obraSocial: '',
      numeroAfiliado: '',
      direccion: '',
      fechaNacimiento: new Date(),
      edad:'',
      email: '',
      telPaisBuscador: '',
      telPais: '',
      telCaract: '',
      telNumero: '',
      celPaisBuscador: '',
      celPais: '',
      celCaract: '',
      celNumero: '',
      codigoInterno: '',
      memberContactNo: 'Seleccionar archivo',
      modificar: true,
      password: '',
      repeatPassword: '',
    });
    this.checked = true; 
    this.divImagen.nativeElement.style.display = "none";
  }


  //
  fileChangeEvent(event: any) {
    var target = event.target || event.srcElement;
  }


  //
  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message: message[0].toUpperCase() + message.slice(1),
        action: action
      },
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [action]
    })
  }


  //
  openDialogFoto(){
    const dialogRef = this.fotoDialog.open(FotoDialogComponent,{
      width: '50%',
    })

    dialogRef.afterClosed().subscribe(res =>{
      if(res.data == "" && res.img == ""){}
      else{
        if(res.data.id == "ico_paciente.png" || res.data.id == "ico_pacienteM.png"){
          this.myfilename = res.data.id;
          
          this.imagen = 'assets/img/'+res.data.id+'';
          this.nameImage = res.data.id;
          this.divImagen.nativeElement.style.display = "block";
        }
        else{
          this.myfilename = res.data.name
          this.imagen = res.img;
          this.divImagen.nativeElement.style.display = "block";     
          this.file = res.data;
          this.nameImage = "";     
        }
      }
    })
  }


}
