// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  PACIENTE_LOGIN:'DOCUMENTO', //MAIL o DOCUMENTO
  MEDICO_LOGIN:'DOCUMENTO', //MAIL o DOCUMENTO
  BOTON_CONTACTO:'Enviar Consulta',
  BASE_TYPEOFESTUDIOS: 1,//1 modo normal 0 el otro
};
